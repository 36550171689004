$(document).ready(function(){
    // 電腦版滿版產品右邊滑過選項會換圖片
    $( '.full-nav ul a' ).hover(
      function() {
        $( this ).addClass( "hover" );
      }, function() {
        $( this ).removeClass( "hover" );
      }
    );
  $('.full-nav ul a').on('mouseenter',  function() {
    var bgname = $(this).data('navbg');
    var bgnamee = $(this).text();

    //- var rightmenu = $('.dropdown-menuright').css('background');

    $('.dropdown-menuright').css('background-image' , "url("+ bgname +")" );
  });
  $('.full-nav ul a').on('mouseout',  function() {
    var bgname = $(this).data('navbg');
    var bgnamee = $(this).text();
    console.log('out');
    //- var rightmenu = $('.dropdown-menuright').css('background');

    $('.dropdown-menuright').css('background-image' , "url(images/no_product-news.jpg)" );
  });
});
